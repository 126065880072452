<template>
  <div>
    <div class="top">在线客服</div>
    <div v-html="service"></div>
  </div>
</template>

<script>
import api from '@/service/api/api'

export default {
  name: "service",
  data() {
    return {
      service: '',
    }
  },
  created() {
    api.service().then(res => {
      // console.log(res)
      this.service = res.data
    })
  },
  methods: {}
}
</script>

<style scoped>
.top {
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-weight: bold;
  font-size: 25px;
  color: #333333;
  text-align: center;
  border-bottom: 1px solid #E5E5E5;
}
</style>